import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }
    refundestadd(postData) {
        return this.http.post<any>(`${environment.apiUrl}re/add`, postData);
      }
      
    signUp(data) {
        return this.http.post<any>(`${environment.apiUrl}user/register`, data);
       
        }
    checkUsername(data) {
            return this.http.post<any>(`${environment.apiUrl}user/checkUsername`, data);
           
            }
    checkEmail(data) {
                return this.http.post<any>(`${environment.apiUrl}user/checkEmail`, data);
               
                }         
    verifyotp(data) {
                    return this.http.post<any>(`${environment.apiUrl}user/checkOtp`, data)
                    .pipe(map(user => {
                        if (user.status == 200) {
                             localStorage.setItem('currentUser', JSON.stringify(user.output));
                         }
                         if (user.error && user.error.errorCode) {
                             this.currentUserSubject.next(null);
                         } else {
                             this.currentUserSubject.next(user.output);
                         }
                         return user;
                     }));
                   
                    }     
        // verifyotp(postData) {
        //     return this.http.post<any>(`${environment.apiUrl}user/checkOtp`, postData)
        //     .pipe(map(user => {
                 
        //         if (user.status == 200) {
        //             localStorage.setItem('currentUser', JSON.stringify(user.output));
        //         }
        //         if (user.output.length == 0) {
        //             this.currentUserSubject.next(null);
        //         } else {
        //             this.currentUserSubject.next(user.output);
        //         }
        //         return user;
        //     }));
        //   }

          resendOtp(data) {
            return this.http.post<any>(`${environment.apiUrl}user/resendOtp`, data)
               .pipe(map(user => {
                    return user;
               }));
              }


          forgotUsername(data) {
            return this.http.post<any>(`${environment.apiUrl}user/forgotUsername`, data)
               .pipe(map(user => {
              
                   return user;
               }));
       }

     
       subscribeadd(data) {
        return this.http.post<any>(`${environment.apiUrl}subscribe/add`, data);
       
        }
       forgotPassword(data) {
        return this.http.post<any>(`${environment.apiUrl}user/forgotPasswordAccountant`, data)
           .pipe(map(user => {
          
               return user;
           }));
        }
       
    login(data) {
         return this.http.post<any>(`${environment.apiUrl}user/login`, data)
            .pipe(map(user => {
               if (user.status == 200) {
                    localStorage.setItem('currentUser', JSON.stringify(user.output));
                }
                if (user.error && user.error.errorCode) {
                    this.currentUserSubject.next(null);
                } else {
                    this.currentUserSubject.next(user.output);
                }
                return user;
            }));
    }


    // login(data) {
    //     let user:any;
    //     user =this.http.post<any>(`${environment.apiUrl}user/login`, data);
    //    if (user.status == 200) {
    //                 localStorage.setItem('currentUser', JSON.stringify(user.output));
    //             }
    //    return user;
        //    .pipe(map(user => {
        //       if (user.status == 200) {
        //            localStorage.setItem('currentUser', JSON.stringify(user.output));
        //        }
        //        if (user.output.length == 0) {
        //            this.currentUserSubject.next(null);
        //        } else {
        //            this.currentUserSubject.next(user.output);
        //        }
        //        return user;
        //    }));
 //  }


    logout() {
       
      
        let formdata:any;
        formdata =  localStorage.getItem('currentUser');
        formdata= JSON.parse(formdata);
       
        return this.http.post<any>(`${environment.apiUrl}user/logout`, {userId:formdata.userId})
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                // console.log("=========user.result", user.result)
                localStorage.removeItem('currentUser');      
                this.currentUserSubject.next(null);
               
                return user;
            }));
    }
}
