import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  //{ path: '', loadChildren: () => import('./modules/landing-page/landing-page.module').then(m => m.LandingPageModule) },
  { path: '', loadChildren: () => import('./modules/sign-in/sign-in.module').then(m => m.SignInModule) },
  { path: 'forgot-password', loadChildren: () => import('./modules/forgot/forgot.module').then(m => m.ForgotModule) },
  { path: 'success', loadChildren: () => import('./modules/success/success.module').then(m => m.SuccessModule) },
  { path: 'dashboard', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'my-clients', loadChildren: () => import('./modules/my-clients/my-clients.module').then(m => m.MyClientsModule) },
  { path: 'notification', loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule) },
  { path: 'change-password', loadChildren: () => import('./modules/change-password/change-password.module').then(m => m.ChangePasswordModule) },
  { path: 'terms-condition', loadChildren: () => import('./modules/terms-condition/terms-condition.module').then(m => m.TermsConditionModule) },
  { path: 'privacy-policy', loadChildren: () => import('./modules/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  { path: 'upload-tax-return', loadChildren: () => import('./modules/upload-tax-return/upload-tax-return.module').then(m => m.UploadTaxReturnModule) },
  { path: 'tax-return', loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule) },
  { path: '**', loadChildren: () => import('./modules/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
