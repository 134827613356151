import { Injectable } from '@angular/core';
//import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authenticationService.currentUserValue;
     //console.log("====================>",currentUser)
       //console.log(currentUser,"Request====================>", request)
        if (currentUser) {
            request = request.clone({
                setHeaders: {
                    Authorization: "Basic " + window.btoa("admin:1234adm2"),
                    "accessToken": currentUser.accessToken
                }
            });
        } else {
          
            request = request.clone({
                setHeaders: {
                    Authorization: "Basic " + window.btoa("admin:1234adm2")
                }
            });

        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                console.log(error);
                let data = {};
                data = {
                    reason: error && error.error.reason ? error.error.reason : '',
                    status: error.status
                };
                localStorage.clear()
                this.router.navigate(['/']);
                return throwError(error);
             })

        )
    }

}
