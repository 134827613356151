// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 // apiUrl:'http://ec2-13-234-249-108.ap-south-1.compute.amazonaws.com:1851/v1/'
  apiUrl:'https://api.taxvolt.com/v1/',
  STRIPE_TEST:'pk_test_51HErG1L8Sm3RZ1EXjVtUuLvxhOayJyYfWTOno6vlKuIe6Sueyg4nQpRsvMCBbDOGLAoonaGy01YkX4fG2gwCAXnZ00S5huqEfd'
};

export const Year = {
  year:(new Date()).getFullYear()
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
